import React from "react";

export default function lastbuttom(){
    return (
        <section class="pb-2 pb-lg-5 border-top footer-top" >
      
        <div class="container" >
          <div class="row border-top-secondary pt-0 text-center">


            <div class="col-lg-12 col-md-6 mb-4 mb-lg-0 order-4 order-md-4 order-lg-3">
              
              <ul class="list-unstyled mb-0">
                <li class="mb-1 footer-col_02">
                  (주)씨에이치파트너스 및 소속상담사는 고객에게 별도의 수수료를 요구하거나 수취하지 않습니다. 중개수수료를 요구하거나 받는것은 불법입니다.
                  <br />
                  대출 이자 및 원금 연체 시 신용상 불이익이 발생할 수 있습니다.
                  <br />
                  대출상품 이용시 귀하의 신용등급 또는 개인신용평점이 하락할 수 있습니다.
                  <br />
                  신용등급 또는 개인신용평점 하락으로 금융거래가 제약될 수 있습니다.
                  <p></p>
                  <br />
                  (주)씨에이치파트너스는 (주)한국투자저축은행과 대출모집위탁계약을 체결한 모집법인 입니다.
                  <br />
                  (주)씨에이치파트너스 및 소속상담사는 한국투자저축은행만의 대출모집업무를 수행하며, 금융상품계약 체결 권한이 있습니다. 
                  <br />
                  대출모집법인 확인방법:  대출모집법인포털사이트(www.loanconsultant.or.kr), 저축은행중앙회(www.fsb.or.kr)
                  <br />
                  민원 발생시 (주)씨에이치파트너스(Tel:070-4144-7355), (주)한국투자저축은행 민원창구(Tel:031-788-3974)로 연락주시기 바랍니다.
                  <p></p>
                  <br />
                  대출계약을 위하여 고객이 제공한 신용정보 또는 개인정보 등은 금융상품직접판매업자인 (주)한국투자저축은행이 직접 보유하고 관리 합니다.
                  <br />
                  (주)씨에이치파트너스가 고의 또는 과실로 금융소비자보호법을 위반하여 고객에게 손해를 발생시킨 경우에는 그 손해를 배상할 책임이 있습니다.
                  <br />
                  (주)씨에이치파트너스가 고의 및 과실이 없음을 입증한 경우를 제외하고 (주)씨에이치파트너스는 금융소비자보호법 제19조(설명의무)를 위반하여
                  <br />
                  고객에게 손해를 발생시킨 경우에는 그 손해를 배상한 책임을 집니다.
                  <br />
                  (주)한국투자저축은행은 금융상품직접판매업자로서 (주)씨에이치파트너스가 대출모집 업무를 할 때 고객에게 손해를
                  <br />
                  발생시킨 경우에는 그 손해를 배상할 책임이 있습니다. 다만, (주)한국투자저축은행이 (주)씨에이치파트너스의 선임과 그 업무 감독에 대하여 적절한 주의를 하였고 
                  <br />
                  손해를 방지하기 위하여 노력한 경우에는 그러하지 않습니다.
                  <p></p>
                  <br />
                  대출모집인 수수료율 확인방법 : 홈페이지 sb.koreainvestment.com 금융도우미 - 고객안내 - 대출모집인 수수료 공시
                  <br />
                  상호 : (주)씨에이치파트너스 | 대표이사 : 최규현 | 사업자등록번호 : 360-87-02027 | 대표번호 : 070-4144-7355 | 개인정보책임자 : 최 규 현
                  <br />
                  은행연합회 대출모집인등록번호 : 20-00000571 | 주소 : 충청북도 청주시 서원구 사운로95, 3층 302호 (사직동)
                  <br />
                  심의필번호 : (주)한국투자저축은행 준법감시인 심의필 제2024-0246호(2024.10.31~2025.10.03)
                  <p></p>
                  <br />
                  [살만한 대출] | 한도 : 100만원 ~ 10,000만원 | 금리 : 연 10.46% ~ 연19.90%
                  <br />
                  기간: 12~120개월 | 상환방법 : 원리금균등상환
                  <br />
                  최대금리 연 19.9% 1,000만원 36개월 원리금균등상환 시, 총 이자는 3,360,554원이며 1회차 상환금액은 371,127원입니다.
                </li>
                
              </ul>
            </div>

          </div>
        </div>

      </section>
    );
}